$primary: #1c1c1c !default; $secondary: #f9fcff !default; $tertiary: #1d6d6d !default; $quaternary: #cb3727 !default; $header_background: #cb3727 !default; $header_primary: #ffffff !default; $highlight: #e6cb37 !default; $danger: #d05454 !default; $success: #71bd9f !default; $love: #c16ad7 !default; $selected: #d2edf3 !default; $hover: #d2edf3 !default;  $public_image_path: "https://ghdev.cnvc.org/images";  @import "common/foundation/variables"; @import "common/foundation/mixins"; .google-dfp-ad {
  padding: 3px 0;
  margin-bottom: 10px;
  clear: both;
}

.google-dfp-ad .dfp-ad-unit {
  margin: 0 auto;
}

.google-adsense {
  padding: 3px 0;
  margin-bottom: 10px;
  clear: both;
}

.google-adsense.adsense-responsive {
  width: 100%;
}

#google-adsense__responsive {
  // Google strips styles added to parent wrapper classes, so we use this ID
  // this sets a minimum height to reduce cumulative layout shift
  min-height: 200px;
}

.google-adsense .google-adsense-label {
  width: 728px;
  max-width: 100%;
  margin: 0 auto;
}

.google-adsense.adsense-responsive .google-adsense-label {
  width: 100%;
  text-align: center;
}

.google-adsense .adsense-unit {
  margin: 0 auto;
}

.google-adsense .google-adsense-label h2 {
  margin: 4px 0 !important;
  color: #858a8c;
  text-transform: uppercase;
  font-size: 12px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
}

.google-adsense .google-adsense-content {
  margin: 0 auto;
}

.google-adsense.adsense-post-bottom {
  max-width: 735px;
  padding: 0 11px;
}

@media all and (max-width: 775px) {
  .google-adsense.adsense-post-bottom {
    box-sizing: border-box;
    width: 100%;
  }
}

.amazon-product-links {
  padding: 3px;
  margin-bottom: 10px;
  clear: both;
}

.amazon-product-links .amazon-unit {
  margin: 0 auto;
}

.amazon-product-links .amazon-product-links-label {
  width: 728px;
  margin: 0 auto;
}

.amazon-product-links .amazon-product-links-label h2 {
  margin: 4px 0 !important;
  color: #858a8c;
  text-transform: uppercase;
  font-size: 12px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
}

.google-dfp-ad .google-dfp-ad-label {
  width: 728px;
  margin: 0 auto;
}

.google-dfp-ad.dfp-ad-post-bottom {
  .google-dfp-ad-label,
  .dfp-ad-unit {
    margin: 0 0 0 52px;
  }
}

@include breakpoint(mobile-extra-large) {
  .google-dfp-ad .google-dfp-ad-label {
    width: 100%;
    text-align: center;
  }
  .google-dfp-ad.dfp-ad-post-bottom {
    .google-dfp-ad-label,
    .dfp-ad-unit {
      margin: 0 auto;
    }
  }
}

.google-dfp-ad .google-dfp-ad-label h2 {
  margin: 4px 0 !important;
  color: #858a8c;
  text-transform: uppercase;
  font-size: 12px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
}

.house-creative {
  margin: 0 auto;
}

.house-creative.house-post-bottom {
  margin: 0 0 10px 52px;
  clear: both;
}

@include breakpoint(mobile-extra-large) {
  .house-creative.house-post-bottom {
    margin: 0 0 10px 0;
  }
}

#carbonads {
  display: block;
  overflow: hidden;
  padding: 1em;
  border: 1px solid #ccc;
  font-family: Verdana, "Helvetica Neue", Helvetica, sans-serif;
  line-height: 1.5;
  margin-bottom: 15px;
}

#carbonads span {
  position: relative;
  display: block;
  overflow: hidden;
}

.carbon-img {
  float: left;
  margin-right: 1em;
}

.carbon-img img {
  display: block;
  line-height: 1;
}

.carbon-text {
  display: block;
  float: left;
  max-width: calc(100% - 130px - 1em);
  text-align: left;
  color: var(--primary-med-or-secondary-med);
}

.carbon-poweredby {
  position: absolute;
  right: 0; // You can also set the position to the "left" with the value of calc(130px + carbon-text’s font size). If the font-size is 12px, you’ll want to set the left value as 142px. It’ll align the .carbon-text with .carbon-poweredby
  bottom: 0;
  display: block;
  font-size: 0.8em;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 1px;
  color: var(--quaternary) !important;
}

.adbutler-ad {
  width: 728px;
  height: 90px;
}

.adbutler-mobile-ad {
  width: 320px;
  height: 50px;
}

.adbutler-topic-list-top,
.adbutler-topic-above-suggested {
  margin: 10px;
  text-align: center;
}

.adbutler-topic-above-post-stream {
  margin: 10px 0 10px 0;
}

.adbutler-post-bottom {
  margin: 10px 0 10px 56px;
}

@include breakpoint(mobile-extra-large) {
  .adbutler-post-bottom {
    margin: 10px 0;
  }
}

.adplugin-mgmt {
  .house-ad-name {
    width: 100%;
  }
  .house-ads-actions {
    .btn {
      margin-right: 8px;
    }
  }
  .house-ads-list {
    margin-top: 1em;
  }
  .house-ads-settings {
    .form-horizontal {
      margin-top: 1em;
    }
    p.help {
      margin: 0;
      margin-top: 5px;
      color: var(--primary-medium);
      font-size: $font-down-1;
      clear: both;
    }
    .house-ads-chooser,
    .house-ads-text-input {
      float: left;
      margin-right: 20px;
    }
    .setting-controls {
      float: left;
    }
  }
  .house-ads-list-setting {
    margin-bottom: 1.5em;
  }
  .content-body {
    padding-left: 2%;
    .visibility-settings {
      margin-bottom: 1em;

      .description {
        color: var(--primary-medium);
        font-size: $font-down-1;
      }

      .category-selector,
      .group-chooser {
        margin-top: 1em;
      }
    }
    .controls {
      margin-bottom: 1em;
    }
    .delete-button {
      float: right;
    }
    .ace-wrapper {
      position: relative;
      height: 270px;
      .ace_editor {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }
}

.house-ad-preview {
  width: 100%;
}
